<template>
  <div>
    <b-card
      v-if="data"
      no-body
      class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Totais</b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
            @click="itemClick(item.url)"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card v-if="showCartao">
      <linkCartao />
    </b-card>
    <b-card
      v-if="showListRemessasFaltando"
      title="Remessas Faltando"
    >
      <RemessasFaltando />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import linkCartao from './LinkCartao.vue'
import RemessasFaltando from './RemessasFaltando.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    linkCartao,
    RemessasFaltando,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCartao: false,
      showListRemessasFaltando: false,
    }
  },
  methods: {
    itemClick(name) {
      if (name === 'linkcartao') {
        this.showListRemessasFaltando = false
        this.showCartao = true
      } else if (name === 'remessas') {
        this.showCartao = false
        this.showListRemessasFaltando = true
      } else {
        this.$router.push({ name })
      }
    },
  },
}
</script>
