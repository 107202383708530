<template>
  <div>
    <b-card>
      <b-col
        md="2"
        offset-md="4"
      />
      <b-row class="mb-2">
        <b-col md="2">
          <label>Filtro por status envio</label>
          <v-select
            v-model="statusFilter"
            :options="optionsStatus"
            multiple
          />
        </b-col>
        <b-col md="4">
          <label>Filtro por credor</label>
          <v-select
            v-model="credoresFilter"
            :options="optionsCredor"
            multiple
          />
        </b-col>

      </b-row>
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="listFiltered"
      >
        <template #head(check)>
          <b-form-checkbox
            v-model="selectAll"
          />
        </template>

        <template #cell(data_acordo)="data">
          {{ data.item.data_acordo ? formatTimezone(data.item.data_acordo) : '' }}
        </template>
        <template #cell(data_vencimento)="data">
          {{ data.item.data_vencimento ? formatTimezone(data.item.data_vencimento) : '' }}
        </template>
        <template #cell(cliente_nome)="data">
          {{ data.item.cliente_nome ? fisrtLastName(data.item.cliente_nome) : '' }}
        </template>
        <template
          #cell(acoes)="data"
        >
          <b-row align-h="center">
            <b-col md="6">
              <feather-icon
                variant="danger"
                icon="Link2Icon"
                class="cursor-pointer"
                @click="openModalCadastrarLink(data.item)"
              />
            </b-col>
            <b-col
              v-if="data.item.link_cartao"
              md="6"
            >
              <feather-icon
                variant="danger"
                icon="SendIcon"
                class="cursor-pointer"
                @click="sendMailLinkRedeDamas(data.item)"
              />
            </b-col>
          </b-row>
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
      />
      <b-modal
        ref="modalCadastrarLink"
        :title="editLink ? 'Editar Link' : 'Cadastrar Link'"
        :ok-title="editLink ? 'Editar' : 'Cadastrar' "
        cancel-title="Cancelar"
        cancel-variant="danger"
        @cancel="closeModal()"
        @ok="cadastrarEnviarLink()"
      >
        <b-row>

          <b-col>
            <label for=""><strong>Link: </strong></label>
            <b-form-input
              v-if="editLink"
              v-model="linkCartaoEdit"
              type="text"
            />
            <b-form-input
              v-else
              v-model="linkCartao"
              type="text"
            />
          </b-col>

        </b-row>
      </b-modal>
    </b-card>
    <b-overlay :show="load" />
  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BFormCheckbox, BFormInput, BPagination, BOverlay, BCard,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BTable,
    BFormCheckbox,
    vSelect,
    BFormInput,
    BPagination,
    BOverlay,
    BCard,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'acordo_id', label: 'ACORDO ID', class: 'text-center' },
        { key: 'cliente_id', label: 'CLIENTE ID', class: 'text-center' },
        { key: 'data_acordo', label: 'DATA ACORDO', class: 'text-center' },
        { key: 'data_vencimento', label: 'DATA VENCIMENTO', class: 'text-center' },
        { key: 'nome_credor', label: 'CREDOR', class: 'text-center' },
        { key: 'cliente_nome', label: 'CLIENTE', class: 'text-center' },
        { key: 'cpf_cliente', label: 'CPF', class: 'text-center' },
        { key: 'quantidade_parcelas', label: 'QNTD. PARCELAS', class: 'text-center' },
        { key: 'forma_pagamento', label: 'FORMA PAGAMENTO', class: 'text-center' },
        { key: 'link_cartao', label: 'LINK', class: 'text-center' },
        { key: 'status_envio', label: 'STATUS ENVIO', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      statusFilter: [],
      credoresFilter: [],
      acordoID: null,
      linkCartao: '',
      currentPage: 1,
      perPage: 1,
      totalRows: 1,
      load: true,
      linkCartaoEdit: '',
      editLink: false,
      linkAcordoInfo: {},
    }
  },
  computed: {
    optionsStatus() {
      const { items } = this
      const status = []

      items.map(item => {
        item.status_envio ? status.push(item.status_envio) : null
      })

      const itemsFiltered = [...new Set(status)]

      return itemsFiltered
    },
    optionsCredor() {
      const { items } = this
      const credores = []

      items.map(item => {
        item.nome_credor ? credores.push(item.nome_credor) : null
      })

      const credoresFiltered = [...new Set(credores)]
      return credoresFiltered
    },
    listFiltered() {
      const {
        items, credoresFilter: cf, statusFilter: sf,
      } = this
      let list = items

      if (cf.length > 0 || sf.length > 0) {
        list = []

        // Filtro por credores
        let newFilterCred = []
        const filterListCred = []
        list.length > 0 ? (newFilterCred = list) : (newFilterCred = items)
        for (let i = 0; i <= cf.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterCred.map(item => {
            if (item.nome_credor === cf[i]) {
              filterListCred.push(item)
            }
          })
          list = filterListCred
        }

        // Filtro por status
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = items)
        for (let i = 0; i <= sf.length - 1; i++) {
          newFilterStatus.map(item => {
            if (item.status_envio === sf[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
      }
      this.totalRows = list.length
      return list
    },

  },
  created() {
    this.getLinks()
  },
  methods: {
    getLinks() {
      axios.get('api/v1/acordo/linkCartaoRedeDamas', {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(res => {
          this.load = false
          res.data.map(items => {
            this.items.push(items)
          })
        })
    },
    openModalCadastrarLink(item) {
      this.linkAcordoInfo = item
      this.acordoID = item.acordo_id
      if (item.link_cartao !== null) {
        this.editLink = true
        this.linkCartaoEdit = item.link_cartao
      }
      this.$refs.modalCadastrarLink.show()
    },
    closeModal() {
      this.acordoID = null
      this.linkCartao = ''
      this.editLink = false
      this.linkCartaoEdit = ''
    },
    clearCadastro() {
      this.items = []
      this.acordoID = null
      this.linkCartao = ''
      this.editLink = false
      this.linkCartaoEdit = ''
    },
    async cadastrarEnviarLink() {
      const { linkAcordoInfo } = this
      await this.cadastrarLink()
      await this.sendMailLinkRedeDamas(linkAcordoInfo)
    },
    cadastrarLink() {
      const body = {
        id: this.acordoID,
        link_cartao: this.editLink ? this.linkCartaoEdit : this.linkCartao,
      }
      axios.post('api/v1/acordo/cadastrarLinkCartao', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(() => {
          this.linkAcordoInfo.link_cartao = body.link_cartao
          this.$swal({
            title: 'Link Cadastrado',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
          this.clearCadastro()
          this.getLinks()
        })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Ocorreu um erro ao cadastrar!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
          this.clearCadastro()
          this.getLinks()
        })
    },

    async sendMailLinkRedeDamas(data) {
      const demonstrativo = await axios.post(`api/v1/acordo/viewDemoSimples/${data.acordo_id}`, null, {
        responseType: 'arraybuffer',
      }).then(res => new File([res.data], 'Demonstrativo.pdf', {
        type: 'application/pdf',
      }))

      const clienteEmails = await axios.get(`api/v1/clientes/emails/${data.cliente_id}`).then(res => res.data.dados.emails)

      const cartaoBody = new FormData()
      cartaoBody.append('name', this.fisrtLastName(data.cliente_nome))
      cartaoBody.append('link', data.link_cartao)
      cartaoBody.append('demonstrativo', demonstrativo)
      cartaoBody.append('idAcordo', data.acordo_id)
      cartaoBody.append('to', JSON.stringify(clienteEmails.map(cliente => ({
        name: this.fisrtLastName(data.cliente_nome), address: cliente.email,
      }))))

      const configEmail = {
        method: 'post',
        url: 'http://192.168.254.219:3000/api/v1/cartao/voz',
        data: cartaoBody,
      }
      await axios(configEmail)

      this.$swal({
        title: 'Link Enviado',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false,
      })
    },
  },
}

</script>
