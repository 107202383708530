<template>
  <div>
    <b-card-header>

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <!-- <feather-icon
          icon="CalendarIcon"
          size="16"
        /> -->
        <!-- <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        /> -->
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="myChart"
        type="bar"
        height="325"
        :options="data.chartOptions"
        :series="data.series"
      />
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardHeader, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardHeader,
    // flatPickr,
    BCardBody,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
