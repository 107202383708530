<template>
  <div>
    <b-overlay :show="load" />
    <b-card>
      <b-col
        md="2"
        offset-md="4"
      />
      <b-row
        class="mb-2"
        align-h="between"
      >
        <b-col md="4">
          <label>Filtro por credor</label>
          <v-select
            v-model="credoresFilter"
            :options="optionsCredor"
            multiple
          />
        </b-col>
        <b-col
          md="2"
          class="budget-wrapper"
        >
          <v-select
            v-model="selectedYear"
            class="mt-2"
            :options="years"
            @input="getYear"
          />
        </b-col>
      </b-row>
      <b-table
        small
        striped
        responsive
        :fields="fields"
        :items="listFiltered"
        :per-page="perPage.selected"
        :current-page="perPage.currentPage"
      >
        <template #cell(mes_faltando)="data">
          {{ converterNumeroMes(data.item.mes_faltando) }}
        </template>
      </b-table>
      <b-pagination
        v-model="perPage.currentPage"
        :total-rows="perPage.totalRows"
        :per-page="perPage.selected"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCol, BRow, BTable, BPagination, BOverlay, BCard,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BTable,
    vSelect,
    BPagination,
    BOverlay,
    BCard,
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'nome', label: 'Credor', class: 'text-center' },
        { key: 'ano', label: 'Ano', class: 'text-center' },
        { key: 'mes_faltando', label: 'Mês Faltando', class: 'text-center' },
      ],
      credoresFilter: [],
      perPage: {
        selected: 50,
        options: [10, 15, 20],
        currentPage: 1,
        totalRows: 1,
      },
      load: true,
      years: [
        2019,
        2020,
        2021,
        2022,
        2023,
      ],
      selectedYear: null,
    }
  },
  computed: {
    optionsCredor() {
      const { items } = this
      const credores = []

      items.map(item => {
        item.nome ? credores.push(item.nome) : null
      })

      const credoresFiltered = [...new Set(credores)]
      return credoresFiltered
    },
    listFiltered() {
      const {
        items, credoresFilter: cf,
      } = this
      let list = items

      if (cf.length > 0) {
        list = []

        // Filtro por credores
        let newFilterCred = []
        const filterListCred = []
        list.length > 0 ? (newFilterCred = list) : (newFilterCred = items)
        for (let i = 0; i <= cf.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterCred.map(item => {
            if (item.nome === cf[i]) {
              filterListCred.push(item)
            }
          })
          list = filterListCred
        }
      }
      this.perPage.totalRows = list.length
      return list
    },

  },
  created() {
    this.getLista(2022)
  },
  methods: {
    getLista(year) {
      this.items = []
      const body = {
        ano: year,
      }
      axios.post('api/v1/remessas/listar/faltando', body, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          accept: 'application/json',
        },
      })
        .then(res => {
          this.load = false
          res.data.map(items => {
            this.items.unshift(items)
          })
          this.perPage.totalRows = this.items.length
        })
    },
    converterNumeroMes(mes) {
      if (mes === 1) { return 'JANEIRO' }
      if (mes === 2) { return 'FEVEREIRO' }
      if (mes === 3) { return 'MARÇO' }
      if (mes === 4) { return 'ABRIL' }
      if (mes === 5) { return 'MAIO' }
      if (mes === 6) { return 'JUNHO' }
      if (mes === 7) { return 'JULHO' }
      if (mes === 8) { return 'AGOSTO' }
      if (mes === 9) { return 'SETEMBRO' }
      if (mes === 10) { return 'OUTUBRO' }
      if (mes === 11) { return 'NOVEMBRO' }
      if (mes === 12) { return 'DEZEMBRO' }
      return mes
    },
    getYear(item) {
      if (item) {
        this.load = true
        this.getLista(item)
      }
    },
  },
}

</script>
