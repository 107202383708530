<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <backoffice-totais :data="data.statisticsItems" />
      </b-col>
    </b-row>
    <b-row align-v="stretch">
      <b-col
        v-if="load.statusOp"
        cols="6"
      >
        <b-card
          title="Status Operação"
        >
          <bar-chart
            :data="statusOp"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="load.motDevolucao"
        cols="6"
      >
        <b-card
          title="Motivo Devolução"
        >
          <bar-chart
            :data="motDevolucao"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="load.classOp"
        cols="6"
      >
        <b-card
          title="Classificação Operação"
        >
          <bar-chart
            :data="classOp"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="load.classCliente"
        cols="6"
      >
        <b-card
          title="Classificação do Cliente"
        >
          <bar-chart
            :data="classCliente"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="load.pendencia"
        cols="auto"
      >
        <b-card title="Pendência">
          <echart-doughnut
            :data="pendencia"
            :total="pendTotal"
          />
        </b-card>
      </b-col>
      <b-row align-v="stretch">
        <b-col
          v-if="load.locCliente"
          cols="12"
        >
          <b-card
            title="Localização do Cliente"
          >
            <localizacao-chart
              :data="locCliente"
              :total="locTotal"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'

import { $themeColors } from '@themeConfig'
import tree from '@/navigation/vertical'
import BarChart from './charts/BarChart.vue'
import BackofficeTotais from './BackofficeTotais.vue'
import axios from '../../../../axios-auth'
import EchartDoughnut from './charts/EchartDoughnut.vue'
import LocalizacaoChart from './charts/LocalizacaoChart.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    LocalizacaoChart,
    EchartDoughnut,
    BarChart,
    BackofficeTotais,
  },
  data() {
    return {
      load: {
        locCliente: false,
        statusOp: false,
        pendencia: false,
        classOp: false,
        classCliente: false,
        motDevolucao: false,
      },
      pendencia: [],
      pendTotal: [],
      locTotal: [],
      statusOp: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          // yaxis: {
          //   // opposite: isRtl,
          // },
        },
      },

      locCliente: {
        series: [
          {
            data: [],
          },
        ],
      },
      motDevolucao: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: tree,
              // barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },

      classOp: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },

      classCliente: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },

      data: {
        statisticsItems: [
          {
            color: 'light-primary',
            customClass: 'mb-2 mb-xl-0 cursor-pointer',
            icon: 'AwardIcon',
            subtitle: 'Credores',
            title: '',
            url: 'credores',
          },
          {
            color: 'light-info',
            customClass: 'mb-2 mb-xl-0 cursor-pointer',
            icon: 'UsersIcon',
            subtitle: 'Clientes',
            title: '',
            url: 'clientes',
          },
          {
            color: 'light-success',
            customClass: 'mb-2 mb-sm-0 cursor-pointer',
            icon: 'BoxIcon',
            subtitle: 'Remessas',
            title: '',
            url: 'remessas',
          },
          {
            color: 'light-danger',
            customClass: 'cursor-pointer',
            icon: 'ArrowDownCircleIcon',
            subtitle: 'Devoluções',
            title: 'devolucao',
            url: 'devolucao',
          },
          {
            color: 'light-success',
            icon: 'BarChart2Icon',
            customClass: 'mt-2 mb-x1-3 cursor-pointer',
            subtitle: 'Quantidade Operações',
          },
          {
            color: 'light-warning',
            icon: 'DollarSignIcon',
            customClass: 'mt-2 mb-x1-3 cursor-pointer',
            subtitle: 'Valor Nominal',
          },
          {
            color: 'light-primary',
            icon: 'PieChartIcon',
            customClass: 'mt-2 mb-x1-3 cursor-pointer',
            subtitle: 'Conversão',
            title: '',
          },
          {
            color: 'light-primary',
            icon: 'CreditCardIcon',
            customClass: 'mt-2 mb-x1-3 cursor-pointer',
            subtitle: 'Link Cartão',
            title: '',
            url: 'linkcartao',
          },
        ],
      },
    }
  },
  async beforeCreate() {
    await axios.get('api/v1/dashboard', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.data.statisticsItems[0].title = res.data.dados.Credores
        this.data.statisticsItems[1].title = res.data.dados.Clientes
        this.data.statisticsItems[2].title = res.data.dados.Remessas
        this.data.statisticsItems[3].title = res.data.dados.Devolucoes
        this.data.statisticsItems[4].title = res.data.dados.Operacoes
        this.data.statisticsItems[5].title = this.valorBr(res.data.dados.Valor_Nominal)
        this.data.statisticsItems[6].title = res.data.dados.Conversao.toFixed(2).concat(' %')
      }).catch(error => {
        if (error.response.status === 401) {
          this.$store.dispatch('auth/logout')
        }
      })
      // TOTAL LINK CARTAO
    axios.get('api/v1/acordo/linkCartaoRedeDamas', {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
        accept: 'application/json',
      },
    })
      .then(res => {
        const totalLink = []
        res.data.map(items => {
          totalLink.push(items)
        })
        this.data.statisticsItems[7].title = totalLink.length
      })

    // GRAFICO STATUS OPERACAO
    this.load.statusOp = false
    await axios.get('api/v1/operacoes/status_operacao_chart', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => {
        this.statusOp.series[0].data.push(dt.indice.toFixed(2))
        this.statusOp.chartOptions.xaxis.categories.push(dt.nome)
      })
      this.load.statusOp = true
    })

    // GRAFICO MOTIVO DEVOLUCAO
    this.load.motDevolucao = false
    await axios.get('api/v1/operacoes/motivo_devolucao_chart', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => {
        this.motDevolucao.series[0].data.push(dt.indice.toFixed(2))
        this.motDevolucao.chartOptions.xaxis.categories.push(dt.nome)
      })
      this.load.motDevolucao = true
    })

    // GRAFICO CLASSIFICACAO OPERACAO
    this.load.classOp = false
    await axios.get('/api/v1/operacoes/motivo_pendencia_chart', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => {
        this.classOp.series[0].data.push(dt.indice.toFixed(2))
        this.classOp.chartOptions.xaxis.categories.push(dt.nome)
      })
      this.load.classOp = true
    })

    // GRAFICO CLASSIFICACAO CLIENTE
    this.load.classCliente = false
    await axios.get('/api/v1/clientes/status_cliente_chart', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      res.data.dados.map(dt => {
        this.classCliente.series[0].data.push(dt.quantidade)
        this.classCliente.chartOptions.xaxis.categories.push(dt.nome)
      })
      this.load.classCliente = true
    })

    // GRAFICO PENDECIA
    this.load.pendencia = false
    await axios.get('/api/v1/operacoes/pendencia_chart', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.pendencia = []
      this.pendTotal = []
      res.data.dados.map(dt => {
        this.pendencia.push({
          name: dt.nome,
          value: dt.quantidade,
        })
      })
      this.pendTotal = res.data.total
      this.load.pendencia = true
    })
    // GRAFICO LOCALIZAÇÃO DO CLIENTE
    await axios.get('api/v1/clientes/cliente_localizacao_chart', {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(res => {
      this.locCliente = []
      this.locTotal = []
      res.data.dados.map(dt => {
        this.locCliente.push({
          name: dt.localizacao,
          value: dt.quantidade,
        })
      })
      this.locTotal = res.data.total
      this.load.locCliente = true
    })
  },
  methods: {},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
